import {useEffect} from "react";

export const useHistoryChangeListener = (callback: any) => {
  useEffect(() => {
    // Save the original methods
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // Wrap pushState
    window.history.pushState = function (...args) {
      const result = originalPushState.apply(this, args);
      window.dispatchEvent(new Event("pushstate"));
      window.dispatchEvent(new Event("locationchange"));
      return result;
    };

    // Wrap replaceState
    window.history.replaceState = function (...args) {
      const result = originalReplaceState.apply(this, args);
      window.dispatchEvent(new Event("replacestate"));
      window.dispatchEvent(new Event("locationchange"));
      return result;
    };

    // Listen to custom events and popstate
    const onLocationChange = () => callback(window.location);
    window.addEventListener("pushstate", onLocationChange);
    window.addEventListener("replacestate", onLocationChange);
    window.addEventListener("popstate", onLocationChange);

    // Cleanup on unmount
    return () => {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
      window.removeEventListener("pushstate", onLocationChange);
      window.removeEventListener("replacestate", onLocationChange);
      window.removeEventListener("popstate", onLocationChange);
    };
  }, [callback]);
};
