import {useEffect, useState} from 'react';
import { THEMES, useLocalStorage } from 'nayan';
import { useSearchParams} from 'react-router-dom';
import { useAppStore } from '@/shared/stores/AppStore.ts';
import VideosPlayer from '@/ui/components/templates/VideosPlayer.tsx';
import {useHistoryChangeListener} from "@/ui/components/helpers/useHistoryChangeListener.tsx";

const VideosView = () => {
  const [qParams]: any = useSearchParams();
  const [theme] = useState(qParams.get('theme'));
  const [template, setTemplate] = useState(JSON.parse(qParams.get('template')));
  const [video, setVideo] = useState(JSON.parse(qParams.get('video')));
  const [_, setPTheme] = useLocalStorage('THEME');

  useHistoryChangeListener((location) => {
    const params = new URLSearchParams(new URL(location.href).search);
    const result: any = {};
    params.forEach((value, key) => {
      try {
        result[key] = JSON.parse(decodeURIComponent(value));
      } catch (error) {
        result[key] = decodeURIComponent(value);
      }
    });
    setVideo(result.video);
    setTemplate(result.template);
  });

  useEffect(() => {
    setPTheme(theme || THEMES.LIGHT);
    useAppStore.setState({ hideWrapper: true });
  }, []);

  return (
    <div className="box-border" style={!!video?.data?.font ? { fontFamily: video.data.font } : {}}>
      <VideosPlayer template={template} video={video} isFullScreen={true} />
    </div>
  );
};

export default VideosView;
